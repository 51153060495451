import React from "react"
import Layout from "../components/Layout"
import "../styles/styles.scss"
import Seo from "../components/Seo"
import HeroAcademy from "../components/HeroAcademy"

const Academy = () => {
  return (
    <Layout>
      <Seo
        title="Academy"
        description="A typical band academy night of music. Develop your chops, your theory and your performance."
      />
      <HeroAcademy />
      <div className="academyWrap">
        <div className="wordWrap">
          <h2>A Typical Academy Night</h2>

          <h3>18:30 - 19:30 Technique & Songs</h3>
          <p>
            The first part of the night is usually about improving technique or
            learning a concept. It's about creating a solid foundation to your
            playing and it allows the coach time to give help where it is
            needed. Everything we do at The Band Academy is always has energy
            and enthusiasm. Yes, even the scales can be fun!
          </p>
          <h3>19:30 - 20:30 Songs & Theory</h3>
          <p>
            The second part of the evening is about learning songs and
            developing practices to understand the songs form or structure. All
            songs are chosen carefully to help us develop musical skill and
            understanding. They may not always be your favourites but they will
            always have educational value. We0will cover many genres, over many
            decades and look at how influences converge and create new and
            exciting ideas. Creating your own songs is also incredibly
            important, it's easier to start creating than many think!!!
          </p>
          <h3>20:30 - 21:30 Live Rehearsal</h3>
          <p>
            The third part of the evening is about live rehearsal. Playing with
            the band is a whole other skill. Learn the basic skills of Staying
            in time and knowing where you are in the song. Then it's about
            listening and watching, being alert and being able to react to how
            the song progresses. There is no end to learning in any of the areas
            we cover. It's not about being perfect, it is about improving and
            enjoying the buzz of playing in the band.
          </p>
        </div>
        <div className="video">
          <div className="videoWrap">
            <h2>Two Types of Musician</h2>
            <iframe
              title="Two types of musicians"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/dXHH5Wy1gGs?start=14?"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div className="wordWrap">
          <h2>Musicians Of The Ear</h2>
          <p>
            The Band Academy is focused on creating musicians of the ear, we
            want you to hear and feel the music so everything we do is geared
            towards this. Ultimately you need to be able to jam, groove, and
            rock.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Academy
