import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Button from "./Button"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"

const HeroAcademy = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "theband.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.image.childImageSharp.fluid

      return (
        <BackgroundImage fluid={image}>
          <div className="sloganHero hero">
            <div className="sloganInner">
              <h1>The Academy</h1>
              <p>A Great Way To Learn</p>

              <Link to="/contact">
                <Button name="BOOK IN" />
              </Link>
            </div>
          </div>
          <div className="waveContainer">
            <div className="waveContainer">
              <div className="svgWrap">
                <svg width="100%" height="450px">
                  <path
                    className="wave"
                    d="M-13,60S88.726,345.069,1346.98,152.505C1914.56,65.643,2000,190.311,2000,190.311V497.587L-13,500V60Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default HeroAcademy
